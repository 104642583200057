import { defineAsyncComponent, h } from 'vue'

const aliases = {}

const hdl = {
  component: props => {
    const { icon, ...rest } = props
    return h(props.tag, rest, [
      h(
        defineAsyncComponent(() => import(`./hdl/hd-icon-${icon}.vue`)),
        { class: 'v-icon__svg' }
      )
    ])
  }
}

export { aliases, hdl }
