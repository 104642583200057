/**
 * @import { AgreementResponses, CartResponse, ContactInfo, RecipientInfo, SurveyResponses } from "#root/rpc/client/cart"
 * @import { Stripe } from "stripe"
 */

import { computed, onBeforeMount, onMounted, ref, watch } from 'vue'
import { createInjectionState } from '@vueuse/shared'
import { PromiseQueue } from '#root/lib/promise-queue'
import { useCartSession } from '#root/lib/useCartSession'
import { useOccasionsStore } from '#root/lib/useOccasionsStore'
import { logger } from '#root/lib/logger'
import { tracker } from '#root/lib/tracker'
import {
  fnLoadCart,
  fnLoadCheckout,
  fnLoadInformation,
  fnSetLineItem,
  fnSubmitCheckout,
  fnSubmitInformation,
  fnUpdatePurchaser,
  fnUpdateRecipients
} from '#root/rpc/client/cart'
import { floatCents, parseCents } from '#root/lib/utils'

// TODO: Implement https://vueuse.org/core/useBroadcastChannel/

/**
 * @param  {any} [err]
 * @return {CartResponse}
 */
function errorCartResponse(err) {
  if (
    err &&
    typeof err === 'object' &&
    'message' in err &&
    typeof err.message === 'string'
  )
    return { cart: null, message: err.message, ok: false }

  return { cart: null, message: 'Unknown error', ok: false }
}

const [useProvideCartStore, useCartStore] = createInjectionState(
  (isInfo = false) => {
    const pq = new PromiseQueue()
    const isFetching = ref(false)
    const isPending = ref(false)
    const isPurchase = ref(false)
    const updates = ref(new Map())
    const {
      cartCount,
      cartId,
      cartReturnTo,
      cartToken,
      infoCartId,
      infoCartToken,
      sessionParams,
      sessionReferrer,
      sessionUpdateTime,
      testMode
    } = useCartSession()
    const {
      fetchTime,
      findOccasions,
      isUpcoming,
      items: occasionItems,
      uniqueIndexes
    } = useOccasionsStore()

    const data = ref(/** @type {CartResponse | null} */ (null))
    const email = ref(/** @type {string | null} */ (null))
    const items = computed(() => {
      let items = null

      if (
        data.value &&
        data.value.cart &&
        data.value.cart.line_items &&
        occasionItems.value &&
        uniqueIndexes.value
      ) {
        items = []

        for (const lineItem of data.value.cart.line_items) {
          const ind =
            uniqueIndexes.value.product_occasion_sku &&
            uniqueIndexes.value.product_occasion_sku[lineItem.sku.toUpperCase()]

          if (typeof ind === 'number') {
            const occasion = occasionItems.value[ind]
            const item = {
              occasion,
              price: lineItem.price,
              price_id: lineItem.price_id,
              price_type: lineItem.price_type,
              quantity: 0,
              recipients: lineItem.recipients || [],
              sku: lineItem.sku,
              source: lineItem.source
            }

            switch (occasion.schedule_status) {
              case 'active':
                item.meta = lineItem.meta
                item.quantity = lineItem.quantity
                break
              case 'cancelled':
              case 'postponed':
              case 'voided':
                item.meta = {
                  alert: {
                    text: `This occasion was ${occasion.schedule_status}.`,
                    type: 'warning'
                  },
                  is_disabled: true
                }
                break
              default:
                item.meta = {
                  alert: {
                    text: 'This occasion is unavailable.',
                    type: 'warning'
                  },
                  is_disabled: true
                }
            }

            items.push(item)
          }
        }
      }

      return items
    })

    const hasShopQuantity = computed(() => {
      return !items.value
        ? false
        : items.value.reduce((acc, cur) => {
            if (cur.quantity > 0 && cur.source === 'shop') return true
            return acc
          }, false)
    })

    const isEditing = computed(() => {
      return updates.value.size > 0
    })

    const subtotal = computed(() => {
      return !items.value
        ? 0
        : floatCents(
            items.value.reduce((acc, cur) => {
              if (typeof cur.price === 'string')
                acc += parseCents(cur.price) * cur.quantity
              return acc
            }, 0)
          )
    })

    /**
     * @return {Promise<CartResponse>}
     */
    async function loadCart() {
      if (data.value) return data.value

      const resp = await fnLoadCart(
        testMode.value,
        cartId.value,
        cartToken.value
      )

      setCartIdAndToken(resp)
      setData(resp)
      setEmail(resp)

      tracker.plausibleEvent('loadCart', {
        testMode: testMode.value,
        cartId: cartId.value || ''
      })

      return resp
    }

    /**
     * @return {Promise<CartResponse>}
     */
    async function loadCheckout() {
      if (data.value) return data.value

      const resp = await fnLoadCheckout(
        testMode.value,
        cartId.value,
        cartToken.value
      )

      setCartIdAndToken(resp)
      setData(resp)
      setEmail(resp)

      tracker.plausibleEvent('loadCheckout', {
        testMode: testMode.value,
        cartId: cartId.value || ''
      })

      return resp
    }

    /**
     * @return {Promise<CartResponse>}
     */
    async function loadInformation() {
      if (data.value) return data.value

      const resp = await fnLoadInformation(
        testMode.value,
        infoCartId.value,
        infoCartToken.value
      )

      setData(resp)
      setEmail(resp)

      if (isPurchase.value && resp.cart && resp.cart.order_number) {
        const currency = 'USD'
        const value = resp.cart.line_items.reduce((acc, cur) => {
          acc += parseFloat(cur.price) * cur.quantity
          return acc
        }, 0)

        tracker.linkedinTrack(16652116)
        tracker.facebookEvent('Purchase', {
          currency,
          num_items: resp.cart.line_items.reduce((acc, cur) => {
            acc += cur.quantity
            return acc
          }, 0),
          value
        })
        const purchaseProps = {
          currency,
          transaction_id: resp.cart.order_number,
          items: resp.cart.line_items.map(item => {
            return {
              item_id: item.sku,
              price: parseFloat(item.price),
              quantity: item.quantity
            }
          }),
          value
        }
        tracker.googleEvent('purchase', purchaseProps)
        tracker.googleEvent('conversion_event_purchase', purchaseProps)
        tracker.plausibleEvent('purchase', {
          testMode: testMode.value,
          cartId: infoCartId.value || '',
          currency,
          orderNumber: resp.cart.order_number,
          value
        })
      } else {
        tracker.plausibleEvent('loadInformation', {
          testMode: testMode.value,
          cartId: infoCartId.value || ''
        })
      }

      return resp
    }

    /**
     * @param  {string} sku
     * @param  {string} price
     * @param  {string} priceId
     * @param  {string} priceType
     * @param  {string} source
     * @param  {string} eventName
     * @return {Promise<CartResponse | undefined>}
     */
    async function addItem(sku, price, priceId, priceType, source, eventName) {
      return pq
        .add(async () => {
          await loadCart()

          if (!(data.value && data.value.cart)) return

          // Update local cart
          const ind = data.value.cart.line_items.findIndex(
            item => item.sku === sku
          )
          if (ind > -1) return

          const quantity = 1

          data.value.cart.line_items.push({
            meta: {
              is_pending: true
            },
            price: price,
            price_id: priceId,
            price_type: priceType,
            quantity,
            sku,
            source
          })

          const resp = await fnSetLineItem(
            testMode.value,
            cartId.value,
            cartToken.value,
            sku,
            {
              price,
              price_id: priceId,
              price_type: priceType,
              quantity,
              source
            }
          )

          setCartIdAndToken(resp)
          setData(resp)
          setEmail(resp)

          if (tracker.posthog && eventName) {
            tracker.posthog.capture(`ecom:${eventName}`, {
              test_mode: testMode.value,
              cart_id: cartId.value || '',
              currency: 'USD',
              price: parseFloat(price),
              price_type: priceType,
              quantity,
              sku,
              source
            })
          }

          tracker.facebookEvent('AddToCart', {
            currency: 'USD',
            value: parseFloat(price)
          })
          const addToCartProps = {
            currency: 'USD',
            value: parseFloat(price),
            items: [
              {
                item_id: sku,
                price: parseFloat(price),
                quantity
              }
            ]
          }
          tracker.googleEvent('add_to_cart', addToCartProps)
          tracker.googleEvent('conversion_event_add_to_cart_1', addToCartProps)
          tracker.plausibleEvent('addItem', {
            testMode: testMode.value,
            cartId: cartId.value || '',
            currency: 'USD',
            price,
            priceType,
            sku,
            source
          })

          return resp
        })
        .catch(err => errorCartResponse(err))
    }

    /**
     * @param  {string} eid
     * @return {Promise<CartResponse | undefined>}
     */
    async function addItemByEBEventId(eid) {
      const data = await findOccasions(fetchTime.value, testMode.value)
      if (!(data.data && data.unique_indexes)) return

      const ind =
        data.unique_indexes.eb_event_id && data.unique_indexes.eb_event_id[eid]
      if (typeof ind !== 'number') return

      const occasion = data.data[ind]
      if (!occasion) return

      if (!isUpcoming(occasion, fetchTime.value)) return

      return addItem(
        occasion.sku,
        occasion.list_price_usd,
        occasion.stripe_list_price_id,
        'list',
        'shop',
        'item_by_eid_add'
      )
    }

    /**
     * @param  {string} sku
     * @return {Promise<CartResponse | undefined>}
     */
    async function addItemBySKU(sku) {
      const data = await findOccasions(fetchTime.value, testMode.value)
      if (!(data.data && data.unique_indexes)) return

      const ind =
        data.unique_indexes.product_occasion_sku &&
        data.unique_indexes.product_occasion_sku[sku.toUpperCase()]
      if (typeof ind !== 'number') return

      const occasion = data.data[ind]
      if (!occasion) return

      if (!isUpcoming(occasion, fetchTime.value)) return

      return addItem(
        occasion.sku,
        occasion.list_price_usd,
        occasion.stripe_list_price_id,
        'list',
        'shop',
        'item_by_sku_add'
      )
    }

    /**
     * @param  {string} sku
     */
    async function beforeUpdateQuantity(sku) {
      if (!(data.value && data.value.cart)) return

      updates.value.set(sku, '')
    }

    function emptyCart() {
      cartCount.value = 0
      cartId.value = null
      cartToken.value = null
      sessionParams.value = null
      sessionReferrer.value = ''
      sessionUpdateTime.value = 0
      data.value = null
      email.value = null
    }

    function fetchCart() {
      isFetching.value = true

      return pq.add(loadCart).finally(() => (isFetching.value = false))
    }

    function fetchCheckout() {
      isFetching.value = true

      return pq.add(loadCheckout).finally(() => (isFetching.value = false))
    }

    function fetchInformation() {
      isFetching.value = true

      return pq.add(loadInformation).finally(() => (isFetching.value = false))
    }

    /**
     * @param  {string} sku
     * @return {Promise<CartResponse>}
     */
    async function removeItem(sku) {
      return pq
        .add(async () => {
          await loadCart()

          if (!(data.value && data.value.cart)) return

          // Update local cart
          const ind = data.value.cart.line_items.findIndex(
            item => item.sku === sku
          )
          if (ind > -1) {
            data.value.cart.line_items[ind].meta = {
              is_pending: true
            }
          }

          const resp = await fnSetLineItem(
            testMode.value,
            cartId.value,
            cartToken.value,
            sku,
            {
              quantity: -1
            }
          )

          setCartIdAndToken(resp)
          setData(resp)

          tracker.plausibleEvent('removeItem', {
            testMode: testMode.value,
            cartId: cartId.value || '',
            sku
          })

          return resp
        })
        .catch(err => errorCartResponse(err))
    }

    /**
     * @param  {CartResponse} resp
     */
    function setCartIdAndToken(resp) {
      if (!(resp && resp.cart)) return

      cartId.value = resp.cart.id
      cartToken.value = resp.cart.token
    }

    /**
     * @param  {CartResponse} resp
     */
    function setData(resp) {
      if (!(resp && resp.cart)) return

      data.value = resp
    }

    /**
     * @param  {CartResponse} resp
     */
    function setEmail(resp) {
      if (!(resp && resp.cart)) return

      email.value =
        resp.cart.purchaser && typeof resp.cart.purchaser.email === 'string'
          ? resp.cart.purchaser.email
          : null
    }

    /**
     * @param  {string} location
     * @param  {boolean} payment
     * @return {Promise<CartResponse>}
     */
    async function submitCheckout(location, payment) {
      isPending.value = true

      return pq
        .add(async () => {
          await loadCart()

          if (!(data.value && data.value.cart)) return
          if (typeof email.value !== 'string') return
          if (!items.value) return

          const lineItems = items.value
            .filter(item => item.quantity > 0)
            .map(item => ({
              price: item.price,
              price_id: item.price_id,
              price_type: item.price_type,
              quantity: item.quantity,
              sku: item.sku,
              source: item.source
            }))

          /** @type {Stripe.MetadataParam}  */
          const metadata = {}

          if (sessionParams.value) {
            for (const [key, value] of Object.entries(sessionParams.value)) {
              if (
                (key === 'aff' ||
                  key.startsWith('gad_') ||
                  key.startsWith('hsa_') ||
                  key.startsWith('utm_')) &&
                typeof value === 'string'
              ) {
                metadata[key] = value
              }
            }
          }

          if (sessionReferrer.value) metadata.referrer = sessionReferrer.value

          /** @type {string | null} */
          let distinctId = null
          try {
            distinctId = await tracker.getDistinctId(email.value)
          } catch (err) {
            console.error({ err }, 'getDistinctId error')
          }
          if (distinctId && tracker.posthog) {
            tracker.posthog.identify(distinctId, {
              email: email.value
            })
          }
          if (tracker.posthog) {
            tracker.posthog.capture(
              payment
                ? 'ecom:checkout_to_payment_submit'
                : 'ecom:checkout_email_submit',
              {
                test_mode: testMode.value,
                cart_id: cartId.value || ''
              }
            )
          }

          /** @type {string | undefined} */
          let clientId
          try {
            clientId = await tracker.getGAClientId()
          } catch (err) {
            logger.error({ err }, 'getGAClientId error')
          }
          if (clientId) metadata.ga_client_id = clientId

          tracker.plausibleEvent('submitCheckout', {
            testMode: testMode.value,
            cartId: cartId.value || '',
            clientId: clientId || '',
            payment
          })

          const resp = await fnSubmitCheckout(
            testMode.value,
            cartId.value,
            cartToken.value,
            email.value,
            lineItems,
            distinctId,
            location,
            metadata,
            payment
          )

          if (!payment) cartReturnTo.value = location

          setCartIdAndToken(resp)
          if (!resp.ok) setData(resp)

          return resp
        })
        .catch(err => {
          isPending.value = false
          return errorCartResponse(err)
        })
    }

    /**
     * @return {Promise<CartResponse>}
     */
    async function submitInformation() {
      isPending.value = true

      return pq
        .add(async () => {
          await loadInformation()

          if (!(data.value && data.value.cart)) return
          if (!infoCartId.value) return
          if (!infoCartToken.value) return
          if (!items.value) return
          if (!data.value.cart.purchaser) return
          if (!data.value.cart.agreement_responses) return
          if (!data.value.cart.survey_responses) return

          const lineItems = items.value
            .filter(item => item.quantity > 0)
            .map(item => ({
              price: item.price,
              price_id: item.price_id,
              price_type: item.price_type,
              quantity: item.quantity,
              recipients: item.recipients,
              sku: item.sku,
              source: item.source
            }))
          const resp = await fnSubmitInformation(
            testMode.value,
            infoCartId.value,
            infoCartToken.value,
            data.value.cart.purchaser,
            data.value.cart.agreement_responses,
            data.value.cart.survey_responses,
            lineItems
          )

          tracker.plausibleEvent('submitInformation', {
            testMode: testMode.value,
            cartId: infoCartId.value || ''
          })

          return resp
        })
        .catch(err => {
          isPending.value = false
          return errorCartResponse(err)
        })
    }

    /**
     * @param  {string} sku
     * @param  {number} quantity
     * @return {Promise<CartResponse>}
     */
    async function updateQuantity(sku, quantity) {
      return pq
        .add(async () => {
          await loadCart()

          if (!(data.value && data.value.cart)) return

          // Update local cart
          const ind = data.value.cart.line_items.findIndex(
            item => item.sku === sku
          )
          if (ind > -1) {
            data.value.cart.line_items[ind].meta = {
              is_pending: true
            }
            data.value.cart.line_items[ind].quantity = quantity
          } else {
            return
          }

          const resp = await fnSetLineItem(
            testMode.value,
            cartId.value,
            cartToken.value,
            sku,
            {
              quantity
            }
          )

          setCartIdAndToken(resp)
          setData(resp)

          tracker.plausibleEvent('updateQuantity', {
            testMode: testMode.value,
            cartId: cartId.value || '',
            quantity,
            sku
          })

          return resp
        })
        .catch(err => errorCartResponse(err))
        .finally(() => updates.value.delete(sku))
    }

    /**
     * @param  {ContactInfo} contactInfo
     * @param  {AgreementResponses} agreementResponses
     * @param  {SurveyResponses} surveyResponses
     * @return {Promise<CartResponse>}
     */
    async function updatePurchaser(
      contactInfo,
      agreementResponses,
      surveyResponses
    ) {
      isPending.value = true

      return pq
        .add(async () => {
          await loadInformation()

          if (!(data.value && data.value.cart)) return
          if (!infoCartId.value) return
          if (!infoCartToken.value) return

          const resp = await fnUpdatePurchaser(
            testMode.value,
            infoCartId.value,
            infoCartToken.value,
            contactInfo,
            agreementResponses,
            surveyResponses
          )

          setData(resp)

          tracker.plausibleEvent('updatePurchaser', {
            testMode: testMode.value,
            cartId: infoCartId.value || ''
          })

          return resp
        })
        .catch(err => errorCartResponse(err))
        .finally(() => (isPending.value = false))
    }

    /**
     * @param  {string} sku
     * @param  {RecipientInfo[]} recipientInfos
     * @return {Promise<CartResponse>}
     */
    async function updateRecipients(sku, recipientInfos) {
      isPending.value = true

      return pq
        .add(async () => {
          await loadInformation()

          if (!(data.value && data.value.cart)) return
          if (!infoCartId.value) return
          if (!infoCartToken.value) return

          const resp = await fnUpdateRecipients(
            testMode.value,
            infoCartId.value,
            infoCartToken.value,
            sku,
            recipientInfos
          )

          setData(resp)

          tracker.plausibleEvent('updateRecipients', {
            testMode: testMode.value,
            cartId: infoCartId.value || '',
            sku
          })

          return resp
        })
        .catch(err => errorCartResponse(err))
        .finally(() => (isPending.value = false))
    }

    onBeforeMount(() => {
      const url = new URL(window.location.href)
      const hash = window.location.hash
      const cid = url.searchParams.get('cid')

      if (cid && hash.length > 1) {
        try {
          const token = atob(decodeURIComponent(hash.substring(1)))

          if (token.length === 36 && cid.length === 24) {
            if (isInfo) {
              infoCartId.value = 'cart_' + cid
              infoCartToken.value = token

              // Track whether this is from a purchase redirect
              isPurchase.value = url.searchParams.has('purchase')

              // Empty the original cart if it's still around
              if (infoCartId.value === cartId.value) emptyCart()
            } else {
              emptyCart()

              cartId.value = 'cart_' + cid
              cartToken.value = token
            }

            window.location.hash = ''
          }
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
        } catch (err) {
          logger.warn('Not a valid cart id or token')
        }
      }
    })

    onMounted(async () => {
      if (new Date().getTime() - sessionUpdateTime.value > 1800000) {
        sessionParams.value = {}
        sessionReferrer.value = ''
      }

      /** @type {{[name: string]: string}} */
      const newSessionParams = {}
      const params = new URLSearchParams(window.location.search)
      params.sort()

      const keys = [...new Set(params.keys())]
      for (const key of keys) {
        const value = params.getAll(key).join(',')
        if (key && value) newSessionParams[key] = value
      }

      sessionParams.value = Object.assign(
        {},
        sessionParams.value,
        newSessionParams
      )

      if (document.referrer) {
        const referrerURL = new URL(document.referrer)
        if (
          (referrerURL.host !== window.location.host ||
            referrerURL.pathname.startsWith('/widgets/')) &&
          referrerURL.host !== 'checkout.stripe.com'
        ) {
          sessionReferrer.value = document.referrer
        }
      }

      sessionUpdateTime.value = new Date().getTime()
    })

    if (!isInfo) {
      watch(items, newValue => {
        cartCount.value = (newValue && newValue.length) || 0
      })
    }

    return {
      addItem,
      addItemByEBEventId,
      addItemBySKU,
      beforeUpdateQuantity,
      data,
      email,
      emptyCart,
      fetchCart,
      fetchCheckout,
      fetchInformation,
      hasShopQuantity,
      isEditing,
      isFetching,
      isPending,
      isPurchase,
      items,
      removeItem,
      submitCheckout,
      submitInformation,
      subtotal,
      updateQuantity,
      updatePurchaser,
      updateRecipients
    }
  }
)

export { useProvideCartStore, useCartStore }
