import { computed, ref } from 'vue'
import { contentProvider } from '#root/renderer/contentProvider'
import { computedAsync, useMemoize } from '@vueuse/core'
import { createInjectionState } from '@vueuse/shared'
import { useCartSession } from '#root/lib/useCartSession'
import { DateTime } from 'luxon'

const [useProvideOccasionsStore, useOccasionsStore] = createInjectionState(
  () => {
    const { testMode } = useCartSession()
    const isFetching = ref(false)
    const fetchTime = ref(new Date().getTime())
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const findOccasions = useMemoize(async dummy =>
      contentProvider.findOccasions(true, testMode.value)
    )
    const showPast = ref(false)
    const data = computedAsync(
      async () => findOccasions(fetchTime.value),
      null,
      {
        evaluating: isFetching,
        lazy: true
      }
    )
    const indexes = computed(() => {
      return data.value && data.value.indexes
    })
    const uniqueIndexes = computed(() => {
      return data.value && data.value.unique_indexes
    })
    const items = computed(() => {
      return data.value && data.value.data
    })
    const upcomingIndicies = computed(() => {
      const indicies = []

      if (items.value) {
        items.value.forEach((item, i) => {
          if (isUpcoming(item, fetchTime.value)) indicies.push(i)
        })
      }

      return indicies
    })
    const upcomingItems = computed(() => {
      return upcomingIndicies.value.map(i => items.value[i])
    })
    const yearMonthIndex = computed(() => {
      return items.value
        ? items.value.reduce((acc, cur, i) => {
            const starts = DateTime.fromISO(cur.starts_at_utc)

            if (starts.valueOf() - fetchTime.value > 0 || showPast.value) {
              const key = starts.toLocal().toFormat('yyyy-LL')
              if (acc[key] === undefined) acc[key] = []
              acc[key].push(i)
            }

            return acc
          }, {})
        : {}
    })

    function isUpcoming(item, time) {
      return (
        (new Date(item.starts_at_utc).getTime() - time > 0 &&
          item.schedule_status === 'active' &&
          item.stripe_floor_price_id &&
          item.stripe_list_price_id) ||
        showPast.value
      )
    }

    function refetchData() {
      findOccasions.clear()
      fetchTime.value = new Date().getTime()
    }

    return {
      fetchTime,
      findOccasions,
      indexes,
      isFetching,
      isUpcoming,
      items,
      refetchData,
      showPast,
      uniqueIndexes,
      upcomingIndicies,
      upcomingItems,
      yearMonthIndex
    }
  }
)

export { useProvideOccasionsStore, useOccasionsStore }
