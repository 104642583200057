import { mdiArrowRight, mdiEmailOutline, mdiOpenInNew } from '@mdi/js'
import kebabCase from 'lodash.kebabcase'
import {
  APP_BAR_HEIGHT,
  FILTER_TYPE_ARTICLE,
  FILTER_TYPE_CASE_STUDY,
  FILTER_TYPE_DOWNLOAD,
  FILTER_TYPE_VIDEO
} from './consts'

const USD = new Intl.NumberFormat('en-US', {
  currency: 'USD',
  maximumFractionDigits: 2,
  minimumFractionDigits: 0,
  style: 'currency'
})

const priceFormatters = {
  USD
}

export function articleHref(value, all) {
  return all
    ? `/resources?type=${FILTER_TYPE_ARTICLE}`
    : `/articles/${slugify(value)}`
}

export function caseStudyHref(value, all) {
  return all
    ? `/resources?type=${FILTER_TYPE_CASE_STUDY}`
    : `/case-studies/${slugify(value)}`
}

export function downloadHref(value, all) {
  return all
    ? `/resources?type=${FILTER_TYPE_DOWNLOAD}`
    : `/downloads/${slugify(value)}`
}

export function fileHref(value) {
  if (value && value.url)
    return value.url.replace(
      import.meta.env.VITE_UPLOADS_ORIGIN,
      import.meta.env.VITE_UPLOADS_CDN
    )
}

export function videoHref(value, all) {
  return all
    ? `/resources?type=${FILTER_TYPE_VIDEO}`
    : `/videos/${slugify(value)}`
}

export function isArticle(value) {
  return (
    value.component === 'Article' || value.__component === 'content.ArticleItem'
  )
}

export function isCaseStudy(value) {
  const item = value.item || value
  return (
    value.component === 'CaseStudy' ||
    value.__component === 'content.CaseStudyItem' ||
    (item.resource_types &&
      item.resource_types.find(({ id }) => id === FILTER_TYPE_CASE_STUDY))
  )
}

export function isDownload(value) {
  return (
    value.component === 'Download' ||
    value.__component === 'content.DownloadItem'
  )
}

export function isFaq(value) {
  return value.component === 'Faq' || value.__component === 'content.FaqItem'
}

export function isVideo(value) {
  return (
    value.component === 'Video' || value.__component === 'content.VideoItem'
  )
}

export function introBody(value) {
  let body = value || ''
  //
  // Apply styles and adjust heading levels
  // Pretty safe since html is generated by markdown-it
  //
  body = body.replaceAll('<img src="', '<img class="img-responsive" src="')
  body = body.replaceAll('<a href="/', '<a class="text-secondary" href="/')
  body = body.replaceAll(
    '<a href="',
    '<a class="text-secondary" target="_blank" href="'
  )
  body = body.replaceAll('<p>', '<p class="text-body-1 mb-3">')
  body = body.replaceAll('<ol>', '<ol class="text-body-1 mb-3 ml-6">')
  body = body.replaceAll('<ul>', '<ul class="text-body-1 mb-3 ml-6">')
  body = body.replaceAll(
    '<blockquote>',
    '<blockquote class="blockquote mb-3 mx-6">'
  )
  body = body.replaceAll('<h6>', '<h6 class="text-subtitle-2 mb-3 mt-4">')
  body = body.replaceAll('<h5>', '<h6 class="text-subtitle-1 mb-3 mt-4">')
  body = body.replaceAll('</h5>', '</h6>')
  body = body.replaceAll('<h4>', '<h5 class="text-h6 mb-3 mt-4">')
  body = body.replaceAll('</h4>', '</h5>')
  body = body.replaceAll('<h3>', '<h4 class="text-h6 mb-3 mt-4">')
  body = body.replaceAll('</h3>', '</h4>')
  body = body.replaceAll('<h2>', '<h3 class="text-h5 mb-3 mt-4">')
  body = body.replaceAll('</h2>', '</h3>')
  body = body.replaceAll('<h1>', '<h2 class="text-h4 mb-3 mt-2">')
  body = body.replaceAll('</h1>', '</h2>')
  body = body.replaceAll('<hr>', '<hr class="my-6">')

  return body
}

export function resourceBody(value) {
  let body = value || ''
  //
  // Apply styles
  // Pretty safe since html is generated by markdown-it
  //
  body = body.replaceAll('<img src="', '<img class="img-responsive" src="')
  body = body.replaceAll('<a href="/', '<a class="text-secondary" href="/')
  body = body.replaceAll(
    '<a href="',
    '<a class="text-secondary" target="_blank" href="'
  )
  body = body.replaceAll('<p>', '<p class="text-body-0 mb-4">')
  body = body.replaceAll('<ol>', '<ol class="text-body-0 mb-4 ml-6">')
  body = body.replaceAll('<ul>', '<ul class="text-body-0 mb-4 ml-6">')
  body = body.replaceAll('<blockquote>', '<blockquote class="blockquote mx-6">')
  body = body.replaceAll('<h6>', '<h6 class="text-subtitle-2 mb-4 mt-5">')
  body = body.replaceAll('<h5>', '<h5 class="text-subtitle-1 mb-4 mt-5">')
  body = body.replaceAll('<h4>', '<h4 class="text-h6 mb-4 mt-5">')
  body = body.replaceAll('<h3>', '<h3 class="text-h5 mb-4 mt-5">')
  body = body.replaceAll('<h2>', '<h2 class="text-h4 mb-4 mt-5">')
  body = body.replaceAll('<h1>', '<h1 class="text-h3 mb-4 mt-3">')

  return body
}

export function resourceHref(value, all) {
  let href = '/'
  if (isArticle(value)) href = articleHref(value, all)
  else if (isCaseStudy(value)) href = caseStudyHref(value, all)
  else if (isDownload(value)) href = downloadHref(value, all)
  else if (isFaq(value)) href = '/faqs'
  else if (isVideo(value)) href = videoHref(value, all)
  return href
}

export function resourceType(value, plural) {
  let name = ''
  if (isArticle(value)) name = plural ? 'Articles' : 'Article'
  else if (isCaseStudy(value)) name = plural ? 'Case Studies' : 'Case Study'
  else if (isDownload(value)) name = plural ? 'Downloads' : 'Download'
  else if (isFaq(value)) name = plural ? 'FAQ’s' : 'FAQ'
  else if (isVideo(value)) name = plural ? 'Videos' : 'Video'
  return name
}

export function slugId(slug) {
  if (typeof slug === 'string') {
    const parts = slug.split('-')
    return parseInt(parts[parts.length - 1]) || 0
  }
  return 0
}

export function slugify(value) {
  if (value) {
    if (value.item) return slugify(value.item)
    if (value.resource) return slugify(value.resource)
    if (value.slug) return value.slug
    if (value.tag) return kebabCase(value.tag)
    if (value.title && value.id) return `${kebabCase(value.title)}-${value.id}`
    if (value.title) return kebabCase(value.title)
    if (value.name) return kebabCase(value.name)
  }
}

export function hrefIcon(value, local = true) {
  if (typeof value === 'string') {
    if (value.startsWith('https:')) return mdiOpenInNew
    if (value.startsWith('mailto:')) return mdiEmailOutline
  }
  return local ? mdiArrowRight : undefined
}

export function hrefTarget(value) {
  return typeof value === 'string' && value.startsWith('/')
    ? undefined
    : '_blank'
}

export function mailToHref({
  body,
  email = import.meta.env.VITE_EMAIL,
  subject = 'Help'
}) {
  const parts = []
  if (subject) parts.push(`subject=${encodeURIComponent(subject)}`)
  if (body) parts.push(`body=${encodeURIComponent(body)}`)
  return `mailto:${email}?${parts.join('&')}`
}

export function telHref({ phone }) {
  return phone ? `tel:${phone.replace(/[^0-9+]/g, '')}` : ''
}

// TODO: Deprecate
export function price(value, defaultValue = '', currency = 'USD') {
  return value === undefined || value === null
    ? defaultValue
    : typeof value !== 'number'
      ? value
      : priceFormatters[currency]
        ? priceFormatters[currency].format(value / 100)
        : value
}

export function priceFmt(value, defaultValue = '') {
  return value === undefined || value === null
    ? defaultValue
    : typeof value === 'string'
      ? USD.format(parseFloat(value))
      : typeof value === 'number'
        ? USD.format(value)
        : value
}

export function scrollToRef(value, options = {}) {
  const opts = {
    behavior: 'smooth',
    height: 0,
    ...options
  }
  let top = 0
  let v = value

  if (v && !v.$el && v.value) v = v.value
  if (v && v.$el) top = v.$el.offsetTop - APP_BAR_HEIGHT - opts.height

  window.scrollTo({
    top,
    left: 0,
    behavior: opts.behavior
  })
}

export async function scrollToRefKey(
  refs,
  key = window.location.hash,
  options = {}
) {
  const k =
    typeof key === 'string' && key.startsWith('#')
      ? key.substring(1)
      : typeof key === 'string'
        ? key
        : slugify(key)
  const ref = refs && k && refs[k]

  if (ref) {
    if (options.delay) await new Promise(resolve => setTimeout(resolve, 500))

    scrollToRef(ref, options)
  }
}

export function floatCents(value) {
  if (typeof value !== 'number') return NaN

  const str = value.toString().padStart(15, '0')
  if (str.includes('.')) return NaN

  return parseFloat(`${str.slice(0, -2)}.${str.slice(-2)}`)
}

export function parseCents(value) {
  if (typeof value !== 'string') return NaN

  const parts = value.split('.')
  if (parts.length > 2) return NaN

  return parts.length > 1
    ? parseInt(`${parts[0]}${parts[1].substring(0, 2).padEnd(2, '0')}`)
    : parseInt(`${parts[0]}00`)
}
