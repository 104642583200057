/**
 * @typedef { import("vue").App } App
 * @typedef { import("vike/types").PageContext } PageContext
 */

import { createSSRApp, h } from 'vue'
import { setPageContext } from './usePageContext'
import LayoutDefault from '#root/layouts/LayoutDefault.vue'
import vuetify from './vuetify'
import { useEventBus } from '@vueuse/core'

export { createApp }

/**
 * @param  {PageContext} pageContext
 * @return {App} app
 */
function createApp(pageContext) {
  const appBus = useEventBus('app')

  const { Page, data } = pageContext
  const PageWithLayout = {
    render() {
      return h(
        pageContext.config.Layout || LayoutDefault,
        {},
        {
          default() {
            return h(Page, data ?? {})
          }
        }
      )
    },
    mounted() {
      setTimeout(() => {
        appBus.emit('ready')
      }, 400)
    }
  }

  const app = createSSRApp(PageWithLayout)

  app.use(vuetify)

  // We make `pageContext` available from any Vue component
  setPageContext(app, pageContext)

  return app
}
