export const CTA_BAR_HEIGHT = 48
export const MENU_BAR_HEIGHT = 64
export const NAV_BAR_HEIGHT = 52
export const APP_BAR_HEIGHT = CTA_BAR_HEIGHT + MENU_BAR_HEIGHT + 1

export const FILTER_CLASSES_ALL = '_all_'
export const FILTER_DELIVERY_METHOD_ALL = '_all_'
export const FILTER_METHODOLOGY_ALL = '_all_'
export const FILTER_MODEL_ALL = '_all_'
export const FILTER_OCCASION_TYPE_ALL = '_all_'
export const FILTER_TIMEZONE_ALL = '_all_'
export const FILTER_TOPIC_ALL = '_all_'
export const FILTER_TYPE_ALL = '_all_'
export const FILTER_TYPE_ARTICLE = 1
export const FILTER_TYPE_CASE_STUDY = 4
export const FILTER_TYPE_DOWNLOAD = 2
export const FILTER_TYPE_VIDEO = 3
export const FILTER_WHAT_ALL = '_all'
export const FILTER_WHEN_HALF_DAY = '_halfday_'
export const FILTER_WHEN_UPCOMING = '_upcoming_'
export const FILTER_WHEN_WEEKEND = '_weekend_'
export const FILTER_WHERE_EVERY = '_everywhere_'
export const FILTER_WHO_ALL = '_all_'

export const ABOUT_VIDEO_GRADIENT =
  'to top right, rgba(33, 72, 119, .5), rgba(33, 72, 119, .5)'
export const COURSE_INTRO_VIDEO_GRADIENT =
  'to top right, rgba(33, 72, 119, .5), rgba(33, 72, 119, .5)'
export const HERO_GRADIENT =
  '180deg, rgba(40,94,139, 0.86) 0%, rgba(40,94,139, 0.86) 86.64%'
export const HOME_CASE_STUDIES_GRADIENT =
  '180deg, rgba(40,94,139, 0.86) 0%, rgba(40,94,139, 0.86) 86.64%'
export const HOME_DIFFERENTIATORS_VIDEO_GRADIENT =
  'to top right, rgba(0, 0, 0, .2), rgba(0, 0, 0, .2)'
export const RESOURCES_VIDEO_GRADIENT =
  'to top right, rgba(0, 0, 0, .2), rgba(0, 0, 0, .2)'
export const SERVICE_DETAIL_VIDEO_GRADIENT =
  'to top right, rgba(33, 72, 119, .5), rgba(33, 72, 119, .5)'
export const TRAINER_VIDEO_GRADIENT =
  'to top right, rgba(33, 72, 119, .5), rgba(33, 72, 119, .5)'
export const MPU_TINT_GRADIENT_BLUE =
  '180deg, rgba(33, 72, 119, 0.75) 0%, rgba(103, 166, 184, 0.75) 100%'
export const MPU_TINT_GRADIENT_DARK =
  'to top right, rgba(0, 0, 0, .6), rgba(0, 0, 0, .6)'
export const WIDGET_COURSE_OVERVIEW_GRADIENT =
  '90deg, rgba(33, 72, 119, 0.75) 0%, rgba(103, 166, 184, 0.75) 100%'
export const WIDGET_TRAINER_INTRO_GRADIENT =
  '90deg, rgba(103, 166, 184, 0.75) 0%, rgba(33, 72, 119, 0.75) 100%'
