import { onMounted, ref, watch } from 'vue'
import { useCartSession } from '#root/lib/useCartSession'
import { useCartStore } from '#root/lib/useCartStore'
import { useEventBus, useStorage, useTimeoutFn } from '@vueuse/core'

const CART_REMINDER_INTERVAL = 20 * 1000 // 20s

export function useCart(options) {
  const { hasCartSession } = useCartSession()
  const { addItem, addItemByEBEventId, addItemBySKU, emptyCart } =
    useCartStore()

  // UI state
  const cartDrawer = ref(false)
  const cartReminderDismissed = useStorage('cart-reminder-dismissed', false)
  const cartReminderSnackbar = ref(false)
  const cartReminderTimeout = useTimeoutFn(() => {
    if (hasCartSession.value && !cartReminderDismissed.value)
      cartReminderSnackbar.value = true
  }, CART_REMINDER_INTERVAL)

  // Event bus
  const cartBus = useEventBus('cart')
  cartBus.on(data => {
    if (data === 'close') {
      closeCart()
    } else if (data === 'empty') {
      emptyCart()
      cartDrawer.value = false
    } else if (data === 'open') {
      openCart()
    } else if (
      data &&
      typeof data === 'object' &&
      'sku' in data &&
      'price' in data &&
      'priceId' in data &&
      'eventName' in data
    ) {
      addItem(
        data.sku,
        data.price,
        data.priceId,
        'list',
        'shop',
        data.eventName
      )
      openCart()
    }
  })

  function closeCart() {
    cartDrawer.value = false
  }

  function dismissCartReminder() {
    cartReminderSnackbar.value = false
    cartReminderDismissed.value = true
  }

  function openCart() {
    cartDrawer.value = true

    if (
      options &&
      typeof options === 'object' &&
      typeof options.onOpen === 'function'
    )
      options.onOpen()

    cartReminderSnackbar.value = false
    cartReminderTimeout.stop()
  }

  onMounted(async () => {
    watch(cartDrawer, (newValue, oldValue) => {
      if (oldValue === true && newValue === false && hasCartSession.value) {
        cartReminderDismissed.value = false
        cartReminderTimeout.start()
      }
    })

    if (
      options &&
      typeof options === 'object' &&
      options.autoAddItemFromHash === true
    ) {
      const hash = window.location.hash

      if (hash.startsWith('#e')) {
        await addItemByEBEventId(hash.substring(2))
        setTimeout(openCart, 500)
      } else if (hash.startsWith('#h')) {
        await addItemBySKU(hash.substring(2))
        setTimeout(openCart, 500)
      }
    }
  })

  return {
    cartDrawer,
    cartReminderSnackbar,
    closeCart,
    dismissCartReminder,
    openCart
  }
}
