export function emailRule(value) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(value) || 'Not a valid email address'
}

export function requiredObjectRule(value) {
  if (value) {
    if (typeof value === 'object') return true
  }
  return 'This field is required'
}

export function requiredRule(value) {
  if (value) {
    if (typeof value === 'string' && value.trim().length > 0) return true
    if (Array.isArray(value) && value.length > 0) return true
  }
  return 'This field is required'
}

export function trueRule(value) {
  if (value === true) return true
  return 'This field must be checked'
}
