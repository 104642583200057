import { computed, ref } from 'vue'
import { StorageSerializers, useStorage } from '@vueuse/core'

export function useCartSession() {
  const cartCount = useStorage('cart-count', 0)
  const cartId = useStorage('cart-id', /** @type {string | null} */ (null))
  const cartReturnTo = useStorage('cart-return-to', '/')
  const cartToken = useStorage(
    'cart-token',
    /** @type {string | null} */ (null)
  )
  const sessionParams = useStorage('session-params', null, undefined, {
    serializer: StorageSerializers.object
  })
  const sessionReferrer = useStorage('session-referrer', '')
  const sessionUpdateTime = useStorage('session-update-time', 0)

  const hasCartSession = computed(() => {
    return (
      typeof cartCount.value === 'number' &&
      cartCount.value > 0 &&
      cartId.value &&
      cartToken.value
    )
  })

  // For post-checkput information capture
  const infoCartId = useStorage('info-cart-id', null)
  const infoCartToken = useStorage('info-cart-token', null)

  const testMode = ref(import.meta.env.VITE_TEST_MODE === 'true')

  return {
    cartCount,
    cartId,
    cartReturnTo,
    cartToken,
    hasCartSession,
    infoCartId,
    infoCartToken,
    sessionParams,
    sessionReferrer,
    sessionUpdateTime,
    testMode
  }
}
