<template>
  <div
    v-resize="resized"
    :style="{
      width: `${playerSize.width}px`,
      height: `${playerSize.height}px`
    }"
    style="overflow: hidden"
  >
    <iframe
      :src="videoSrc"
      allowfullscreen
      frameborder="0"
      style="width: 100%; height: 100%"
      type="text/html"
    />
  </div>
</template>

<script setup>
import { computed, ref } from 'vue'

const props = defineProps({
  value: {
    required: true,
    type: Object
  }
})

const aspectRatio = 16 / 9
const playerSize = ref({ width: 0, height: 0 })
const videoSrc = computed(() => {
  let src

  switch (props.value.player) {
    case 'youtube':
      src = `https://www.youtube.com/embed/${props.value.external_id}?autoplay=1&modestbranding=1`
      break
    case 'YouTube':
      src = `https://www.youtube.com/embed/${props.value.video_id}?autoplay=1&modestbranding=1`
      break
    case 'vimeo':
      src = `https://player.vimeo.com/video/${props.value.external_id}?byline=0`
      break
    case 'Vimeo':
      src = `https://player.vimeo.com/video/${props.value.video_id}?byline=0`
      break
  }

  return src
})

function resized() {
  const { innerWidth, innerHeight } = window

  const boxWidth = innerWidth - 48
  const boxHeight = innerHeight * 0.8
  const boxRatio = boxWidth / boxHeight

  playerSize.value =
    boxRatio > aspectRatio
      ? {
          width: boxHeight * aspectRatio,
          height: boxHeight
        }
      : {
          width: boxWidth,
          height: boxWidth / aspectRatio
        }
}
</script>
