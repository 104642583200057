<template>
  <v-container>
    <v-row class="py-16">
      <v-col cols="12" lg="3">
        <div class="text-h5 mb-4">Contact</div>

        <div class="mb-4">
          {{ siteConfig.location_text }}
        </div>

        <div class="mb-2">
          <a
            :href="
              telHref({
                phone: siteConfig.main_phone
              })
            "
            class="on-surface"
            target="_blank"
            >{{ siteConfig.main_phone }}</a
          >
        </div>

        <div>
          <a
            :href="
              mailToHref({
                email: siteConfig.info_email,
                subject: 'Questions'
              })
            "
            class="on-surface"
            target="_blank"
            >{{ siteConfig.info_email }}</a
          >
        </div>
      </v-col>

      <v-col v-if="siteConfig.footer_nav1" cols="12" lg="3">
        <div v-if="siteConfig.footer_nav1.heading" class="text-h5 mb-4">
          {{ siteConfig.footer_nav1.heading }}
        </div>

        <div
          v-if="
            siteConfig.footer_nav1.items && siteConfig.footer_nav1.items.length
          "
          class="d-flex flex-column align-start ml-n4"
        >
          <v-btn
            v-for="item in siteConfig.footer_nav1.items"
            :key="item.href"
            :href="item.href"
            :target="hrefTarget(item.href)"
            class="text-none"
            exact
            variant="text"
          >
            {{ item.title }}
          </v-btn>
        </div>
      </v-col>

      <v-col v-if="siteConfig.footer_nav2" cols="12" lg="3">
        <div v-if="siteConfig.footer_nav2.heading" class="text-h5 mb-4">
          {{ siteConfig.footer_nav2.heading }}
        </div>

        <div
          v-if="
            siteConfig.footer_nav2.items && siteConfig.footer_nav2.items.length
          "
          class="d-flex flex-column align-start ml-n4"
        >
          <v-btn
            v-for="item in siteConfig.footer_nav2.items"
            :key="item.href"
            :append-icon="hrefIcon(item.href, false)"
            :href="item.href"
            :target="hrefTarget(item.href)"
            class="text-none"
            exact
            variant="text"
          >
            {{ item.title }}
          </v-btn>
        </div>
      </v-col>

      <v-col cols="12" lg="3">
        <div class="text-h5 mb-4">Get Updates</div>

        <NewsletterForm />
      </v-col>
    </v-row>

    <v-divider class="my-10" />

    <v-row align="center">
      <v-col cols="12" md="4" order="last" order-md="first">
        <v-row align="center" class="flex-nowrap">
          <v-col cols="auto">
            <div class="d-none d-sm-flex" style="margin-bottom: -24px">
              <a href="/">
                <HyperdriveLogo collapse />
              </a>
            </div>
          </v-col>

          <v-col>{{ siteConfig.copyright }}</v-col>
        </v-row>
      </v-col>

      <v-col v-if="siteConfig.social_info" align="center" cols="12" md="4">
        <SocialButtons :value="siteConfig.social_info" />
      </v-col>

      <v-col class="text-center text-md-right" cols="12" md="4">{{
        siteConfig.leadership_text
      }}</v-col>
    </v-row>
  </v-container>
</template>

<script setup>
import { usePageContext } from '#root/renderer/usePageContext'
import { hrefIcon, hrefTarget, mailToHref, telHref } from '#root/lib/utils'

const { siteConfig } = usePageContext()
</script>
