// Styles
// import 'vuetify/lib/styles/main.sass'
import 'vuetify/styles'
// Vuetify
import { createVuetify } from 'vuetify'
import * as directives from 'vuetify/lib/directives/index.mjs'
// SEE: https://pictogrammers.github.io/@mdi/font/7.0.96/
import { aliases, mdi } from 'vuetify/iconsets/mdi-svg'
import { hdd } from '#root/iconsets/hdd.js'
import { hdf } from '#root/iconsets/hdf.js'
import { hdl } from '#root/iconsets/hdl.js'
import { hdx } from '#root/iconsets/hdx.js'

const colors = {
  //
  error: 'c9312e',
  info: '#198ab3',
  //
  'blue-base': '#2a5d89',
  'blue-lighten-5': '#e6ebf0', // ok
  'blue-lighten-4': '#c0cdda',
  'blue-lighten-3': '#98adc2',
  'blue-lighten-2': '#718da9',
  'blue-lighten-1': '#507498',
  'blue-darken-1': '#235681',
  'blue-darken-2': '#194c75', // ok
  'blue-darken-3': '#154269',
  'blue-darken-4': '#123150', // ok
  'blue-accent-1': '#82b1ff',
  'blue-accent-2': '#448aff',
  'blue-accent-3': '#2979ff',
  'blue-accent-4': '#2962ff',
  //
  'light-blue-base': '#2eaad2',
  'light-blue-lighten-5': '#e2f5f8', // ok
  'light-blue-lighten-4': '#b5e5ee',
  'light-blue-lighten-3': '#88d4e4',
  'light-blue-lighten-2': '#60c3db',
  'light-blue-lighten-1': '#45b6d6',
  'light-blue-darken-1': '#259dc5',
  'light-blue-darken-2': '#198ab3', // ok
  'light-blue-darken-3': '#1779a0',
  'light-blue-darken-4': '#0b5a7f', // ok
  'light-blue-accent-1': '#80d8ff',
  'light-blue-accent-2': '#40c4ff',
  'light-blue-accent-3': '#00b0ff',
  'light-blue-accent-4': '#0091ea',
  //
  'cyan-base': '#3a747e',
  'cyan-lighten-5': '#ebf9f8', // ok - custom
  'cyan-lighten-4': '#bad7db',
  'cyan-lighten-3': '#96bac1',
  'cyan-lighten-2': '#729da6',
  'cyan-lighten-1': '#578891',
  'cyan-darken-1': '#30656e',
  'cyan-darken-2': '#22525a',
  'cyan-darken-3': '#153f46', // ok
  'cyan-darken-4': '#042a30',
  'cyan-accent-1': '#84ffff',
  'cyan-accent-2': '#28bec6', // ok - custom
  'cyan-accent-3': '#3f7d8c', // ok - legacy
  'cyan-accent-4': '#67a6b8', // ok - legacy
  //
  'red-base': '#ea4435', // ok
  'red-lighten-5': '#feebed',
  'red-lighten-4': '#fcccd1',
  'red-lighten-3': '#e99998',
  'red-lighten-2': '#dd7271',
  'red-lighten-1': '#e6534e',
  'red-darken-1': '#db3b34',
  'red-darken-2': '#c9312e',
  'red-darken-3': '#bc2b27',
  'red-darken-4': '#ad211c',
  'red-accent-1': '#ff8a80',
  'red-accent-2': '#ff5252',
  'red-accent-3': '#ff1744',
  'red-accent-4': '#d50000'
}

export default createVuetify({
  directives,

  icons: {
    defaultSet: 'mdi',
    aliases,
    sets: {
      hdd,
      hdf,
      hdl,
      hdx,
      mdi
    }
  },

  // SEE: https://m2.material.io/design/color/the-color-system.html#tools-for-picking-colors
  theme: {
    themes: {
      dark: {
        dark: true,

        colors: {
          ...colors,
          secondary: '#2eaad2', // light-blue-base
          primary: '#e6ebf0', // blue-lighten-5
          'surface-light': '#212121'
        }
      },

      light: {
        dark: false,

        colors: {
          ...colors,
          secondary: '#198ab3', // light-blue-darken-2
          primary: '#194c75' // blue-darken-2
        }
      }
    }
  }
})
