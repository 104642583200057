export class ContentProvider {
  constructor(options) {
    this.cdn = options.cdn
    this.dev = options.dev
    this.logger = options.logger
    this.origin = options.origin
  }

  async findMany(entity, cdn) {
    const { dev, logger } = this
    const input = `${cdn ? this.cdn : this.origin}/${entity}.json`

    if (dev && logger) logger.info({ input }, 'Fetch findMany')

    const resp = await fetch(input)
    const data = resp.ok ? await resp.json() : {}
    return (data && data.data) || []
  }

  async findOne(entity, id, cdn) {
    const { dev, logger } = this
    const input = id
      ? `${cdn ? this.cdn : this.origin}/${entity}/${id}.json`
      : `${cdn ? this.cdn : this.origin}/${entity}.json`

    if (dev && logger) logger.info({ input }, 'Fetch findOne')

    const resp = await fetch(input)
    return resp.ok ? await resp.json() : {}
  }

  async findEvents(cdn) {
    return this.findOne('events', '', cdn)
  }

  async findOccasions(cdn, test) {
    return this.findOne(
      test ? 'product-occasions.test' : 'product-occasions',
      '',
      cdn
    )
  }

  async findTrainers(cdn) {
    return this.findOne('trainers', '', cdn)
  }

  async getEventById(id, cdn) {
    const data = await this.findOne('events', '', cdn)
    if (
      data &&
      data.index &&
      data.index.byId &&
      Array.isArray(data.index.byId[id])
    ) {
      const ind = data.index.byId[id]
      if (ind.length) return data.data[ind[0]]
    }
  }

  async getTrainerBySlug(slug, cdn) {
    const data = await this.findOne('trainers', '', cdn)
    if (
      data &&
      data.index &&
      data.index.bySlug &&
      Array.isArray(data.index.bySlug[slug])
    ) {
      const ind = data.index.bySlug[slug]
      if (ind.length) return data.data[ind[0]]
    }
  }
}

export function toDocumentProps(data) {
  const arr = Array.isArray(data) ? data : [data]
  const props = {}
  const og = {}

  for (const obj of arr) {
    if (typeof obj.name === 'string') props.title = obj.name
    if (typeof obj.title === 'string') props.title = obj.title
    if (typeof obj.summary === 'string') props.description = obj.summary

    if (obj.meta_info) {
      const info = obj.meta_info

      if (typeof info.title === 'string') props.title = info.title
      if (typeof info.description === 'string')
        props.description = info.description

      if (typeof info.og_description === 'string')
        og.description = info.og_description
      if (typeof info.og_title === 'string') og.title = info.og_title
      if (typeof info.og_image === 'string') og.image = info.og_image
      else if (
        info.og_image &&
        typeof info.og_image === 'object' &&
        typeof info.og_image.url === 'string'
      )
        og.image = info.og_image.url
    }
  }

  if (Object.keys(og).length) props.og = og

  return props
}
