<template>
  <v-navigation-drawer location="right" temporary theme="light" width="700">
    <HdCart />
  </v-navigation-drawer>
</template>

<script setup>
import { defineAsyncComponent } from 'vue'

const HdCart = defineAsyncComponent(() => import('./HdCart.vue'))
</script>
