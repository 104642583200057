import { post } from './client'

export {
  fnLoadCart,
  fnLoadCheckout,
  fnLoadInformation,
  fnSetLineItem,
  fnSubmitCheckout,
  fnSubmitInformation,
  fnUpdatePurchaser,
  fnUpdateRecipients
}

/**
 * @typedef {{[name: string]: boolean}} AgreementResponses
 */

/**
 * @typedef {{[name: string]: string}} SurveyResponses
 */

/**
 * @typedef {object} ContactInfo
 * @property {string} [address_1]
 * @property {string} [address_2]
 * @property {string} [city]
 * @property {string} [company]
 * @property {string} [country]
 * @property {string} email
 * @property {string} [first_name]
 * @property {string} [job_title]
 * @property {string} [last_name]
 * @property {string} [name]
 * @property {string} [phone]
 * @property {string} [postal_code]
 * @property {string} [region]
 */

/**
 * @typedef {(ContactInfo | AgreementResponses)} RecipientInfo
 */

/**
 * @typedef {object} LineItemMetaAlert
 * @property {string} text
 * @property {string} type
 */

/**
 * @typedef {object} LineItemMeta
 * @property {LineItemMetaAlert} [alert]
 * @property {boolean} [is_disabled]
 * @property {boolean} [is_pending]
 */

/**
 * @typedef {object} SetLineItemData
 * @property {string} [price]
 * @property {string} [price_id]
 * @property {string} [price_type]
 * @property {number} quantity
 * @property {string} [source]
 */

/**
 * @typedef {object} LineItem
 * @property {LineItemMeta} [meta]
 * @property {string} price
 * @property {string} price_id
 * @property {string} price_type
 * @property {number} quantity
 * @property {RecipientInfo[]} [recipients]
 * @property {string} sku
 * @property {string} source
 */

/**
 * @typedef {object} HoldsEntry
 * @property {number} expires
 * @property {number} quantity
 */

/**
 * @typedef {{[cid: string]: HoldsEntry}} Holds
 */

/**
 * @typedef {object} OccasionInventory
 * @property {number} claimed
 * @property {number} max
 */

/**
 * @typedef {object} VariantInventory
 * @property {number} available
 */

/**
 * @typedef {object} Cart
 * @property {string} id
 * @property {boolean} is_locked
 * @property {boolean} is_post_checkout
 * @property {LineItem[]} line_items
 * @property {string} [order_number]
 * @property {ContactInfo} [purchaser]
 * @property {AgreementResponses} [agreement_responses]
 * @property {SurveyResponses} [survey_responses]
 * @property {string} token
 * @property {string} updated_at
 */

/**
 * @typedef {object} CartResponse
 * @property {Cart | null} cart
 * @property {boolean} ok
 * @property {string} [message]
 * @property {string} [url]
 */

/**
 * @typedef {object} CheckInventoryResponse
 * @property {LineItemMeta} [meta]
 * @property {number} quantity
 */

/**
 * @param  {boolean} testMode
 * @param  {string | null} cartId
 * @param  {string | null} cartToken
 * @return {Promise<CartResponse>}
 */
async function fnLoadCart(testMode, cartId, cartToken) {
  return post({
    method: 'LoadCart',
    args: [testMode, cartId, cartToken]
  })
}

/**
 * @param  {boolean} testMode
 * @param  {string | null} cartId
 * @param  {string | null} cartToken
 * @return {Promise<CartResponse>}
 */
async function fnLoadCheckout(testMode, cartId, cartToken) {
  return post({
    method: 'LoadCheckout',
    args: [testMode, cartId, cartToken]
  })
}

/**
 * @param  {boolean} testMode
 * @param  {string | null} cartId
 * @param  {string | null} cartToken
 * @return {Promise<CartResponse>}
 */
async function fnLoadInformation(testMode, cartId, cartToken) {
  return post({
    method: 'LoadInformation',
    args: [testMode, cartId, cartToken]
  })
}

/**
 * @param  {boolean} testMode
 * @param  {string | null} cartId
 * @param  {string | null} cartToken
 * @param  {string} sku
 * @param  {SetLineItemData} data
 * @return {Promise<CartResponse>}
 */
async function fnSetLineItem(testMode, cartId, cartToken, sku, data) {
  return post({
    method: 'SetLineItem',
    args: [testMode, cartId, cartToken, sku, data]
  })
}

/**
 * @param  {boolean} testMode
 * @param  {string | null} cartId
 * @param  {string | null} cartToken
 * @param  {string} email
 * @param  {LineItem[]} lineItems
 * @param  {string | null} distinctId
 * @param  {string} location
 * @param  {Stripe.MetadataParam} metadata
 * @param  {boolean} payment
 * @return {Promise<CartResponse>}
 */
async function fnSubmitCheckout(
  testMode,
  cartId,
  cartToken,
  email,
  lineItems,
  distinctId,
  location,
  metadata,
  payment
) {
  return post({
    method: 'SubmitCheckout',
    args: [
      testMode,
      cartId,
      cartToken,
      email,
      lineItems,
      distinctId,
      location,
      metadata,
      payment
    ]
  })
}

/**
 * @param  {boolean} testMode
 * @param  {string | null} cartId
 * @param  {string | null} cartToken
 * @param  {ContactInfo} contactInfo
 * @param  {AgreementResponses} agreementResponses
 * @param  {SurveyResponses} surveyResponses
 * @param  {LineItem[]} lineItems
 * @return {Promise<CartResponse>}
 */
async function fnSubmitInformation(
  testMode,
  cartId,
  cartToken,
  contactInfo,
  agreementResponses,
  surveyResponses,
  lineItems
) {
  return post({
    method: 'SubmitInformation',
    args: [
      testMode,
      cartId,
      cartToken,
      contactInfo,
      agreementResponses,
      surveyResponses,
      lineItems
    ]
  })
}

/**
 * @param  {boolean} testMode
 * @param  {string} cartId
 * @param  {string} cartToken
 * @param  {ContactInfo} contactInfo
 * @param  {AgreementResponses} agreementResponses
 * @param  {SurveyResponses} surveyResponses
 * @return {Promise<CartResponse>}
 */
async function fnUpdatePurchaser(
  testMode,
  cartId,
  cartToken,
  contactInfo,
  agreementResponses,
  surveyResponses
) {
  return post({
    method: 'UpdatePurchaser',
    args: [
      testMode,
      cartId,
      cartToken,
      contactInfo,
      agreementResponses,
      surveyResponses
    ]
  })
}

/**
 * @param  {boolean} testMode
 * @param  {string} cartId
 * @param  {string} cartToken
 * @param  {string} sku
 * @param  {RecipientInfo[]} recipientInfos
 * @return {Promise<CartResponse>}
 */
async function fnUpdateRecipients(
  testMode,
  cartId,
  cartToken,
  sku,
  recipientInfos
) {
  return post({
    method: 'UpdateRecipients',
    args: [testMode, cartId, cartToken, sku, recipientInfos]
  })
}
