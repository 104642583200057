/**
 * @param  {any} data
 * @return {Promise<any>}
 */
export async function post(data) {
  const resp = await fetch('/_rpc', {
    method: 'POST',
    body: JSON.stringify(data),
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json'
    }
  })

  return resp.ok ? await resp.json() : {}
}
