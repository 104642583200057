/**
 * @typedef { import("vike/types").OnRenderClientAsync } OnRenderClient
 */

import '@fontsource/lato/latin-100.css'
import '@fontsource/lato/latin-300.css'
import '@fontsource/lato/latin-400.css'
import '@fontsource/lato/latin-700.css'
import '@fontsource/lato/latin-900.css'
import '@fontsource/poppins/latin-100.css'
import '@fontsource/poppins/latin-200.css'
import '@fontsource/poppins/latin-300.css'
import '@fontsource/poppins/latin-400.css'
import '@fontsource/poppins/latin-500.css'
import '@fontsource/poppins/latin-600.css'
import '@fontsource/poppins/latin-700.css'
import '@fontsource/poppins/latin-800.css'
import '@fontsource/poppins/latin-900.css'
import '@fontsource/roboto/latin-100.css'
import '@fontsource/roboto/latin-300.css'
import '@fontsource/roboto/latin-400.css'
import '@fontsource/roboto/latin-500.css'
import '@fontsource/roboto/latin-700.css'
import '@fontsource/roboto/latin-900.css'

import { tracker } from '#root/lib/tracker'
import { createApp } from './app'

export default onRenderClient

/**
 * @type OnRenderClient
 */
async function onRenderClient(pageContext) {
  const app = createApp(pageContext)
  tracker.pageView(pageContext)
  app.mount('#app')
}
