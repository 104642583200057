<template>
  <component
    :is="chunk.tag"
    v-for="(chunk, i) in parsed"
    :key="i"
    v-bind="chunk.props"
  >
    {{ chunk.text }}
  </component>
</template>

<script setup>
import { computed } from 'vue'
import { hrefTarget } from '#root/lib/utils'

const LINK_REGEX = /\[([^[]+)\]\(([^)]+)\)/g
const STYLE_REGEX = /(\*\*|__)(.*?)\1/g

const props = defineProps({
  emClass: {
    default: 'font-weight-medium',
    type: String
  },
  linkClass: {
    default: 'text-decoration-underline text-secondary',
    type: String
  },
  value: {
    default: 'This is **styled** text.',
    type: String
  }
})

function parseLinks(text) {
  const chunks = []
  let i = 0

  for (const match of text.matchAll(LINK_REGEX)) {
    chunks.push(
      { tag: 'span', text: text.substring(i, match.index) },
      {
        props: {
          class: props.linkClass,
          href: match[2],
          target: hrefTarget(match[2])
        },
        tag: 'a',
        text: match[1]
      }
    )
    i = match.index + match[0].length
  }

  chunks.push({
    tag: 'span',
    text: text.substring(i)
  })

  return chunks
}

const parsed = computed(() => {
  const text = props.value
  const chunks = []
  let i = 0

  for (const match of text.matchAll(STYLE_REGEX)) {
    chunks.push(...parseLinks(text.substring(i, match.index)), {
      props: {
        class: props.emClass
      },
      tag: 'span',
      text: match[2]
    })
    i = match.index + match[0].length
  }

  chunks.push(...parseLinks(text.substring(i)))

  return chunks
})
</script>
