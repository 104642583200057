import { defineAsyncComponent, h } from 'vue'

const aliases = {}

const hdd = {
  component: props => {
    const { color2, icon, ...rest } = props
    return h(props.tag, rest, [
      h(
        defineAsyncComponent(
          () => import(`./hdd/hd-icon-dualtone-${icon}.vue`)
        ),
        {
          class: 'v-icon__svg',
          class2:
            typeof color2 === 'string'
              ? `text-${color2}`
              : `text-light-blue-darken-2`
        }
      )
    ])
  }
}

export { aliases, hdd }
