import type { Logger } from 'pino'
import Plausible from 'plausible-tracker'
import PostHog from 'posthog-js'
import { logger } from './logger'

interface TrackerOptions {
  dev: boolean
  // eslint-disable-next-line @typescript-eslint/no-unsafe-function-type
  fbq?: Function
  // eslint-disable-next-line @typescript-eslint/no-unsafe-function-type
  gtag?: Function
  // eslint-disable-next-line @typescript-eslint/no-unsafe-function-type
  lintrk?: Function
  logger?: Logger
  plausible?: PlausibleReturn
  posthog?: typeof PostHog
}

class Tracker implements TrackerOptions {
  dev: boolean
  // eslint-disable-next-line @typescript-eslint/no-unsafe-function-type
  fbq?: Function
  // eslint-disable-next-line @typescript-eslint/no-unsafe-function-type
  gtag?: Function
  // eslint-disable-next-line @typescript-eslint/no-unsafe-function-type
  lintrk?: Function
  logger?: Logger
  plausible?: PlausibleReturn
  posthog?: typeof PostHog

  constructor(options: TrackerOptions = { dev: false }) {
    this.dev = options.dev
    this.fbq = options.fbq
    this.gtag = options.gtag
    this.lintrk = options.lintrk
    this.logger = options.logger
    this.plausible = options.plausible
    this.posthog = options.posthog
  }

  event(event: string, props = {}) {
    const { dev, fbq, gtag, logger, plausible } = this

    if (!event) return
    if (dev && logger) logger.info({ event, props }, 'Track event')
    if (plausible) plausible.trackEvent(event, { props })
    if (fbq) fbq('track', event, props)
    if (gtag) gtag('event', event, props)
  }

  facebookEvent(event: string, props = {}) {
    const { dev, fbq, logger } = this

    if (!event) return
    if (dev && logger) logger.info({ event, props }, 'Track Facebook event')
    if (fbq) fbq('track', event, props)
  }

  googleEvent(event: string, props = {}) {
    const { dev, gtag, logger } = this

    if (!event) return
    if (dev && logger) logger.info({ event, props }, 'Track Google event')
    if (gtag) gtag('event', event, props)
  }

  linkedinTrack(conversionID: number) {
    const { dev, lintrk, logger } = this

    if (!conversionID) return
    if (dev && logger)
      logger.info({ conversionID }, 'Track LinkedIn conversion')
    if (lintrk) lintrk('track', conversionID)
  }

  plausibleEvent(
    event: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    props: { [key: string]: any; message?: string } = {},
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    err?: any
  ) {
    const { dev, logger, plausible } = this

    if (!event) return
    if (
      err &&
      typeof err === 'object' &&
      'message' in err &&
      typeof err.message === 'string'
    ) {
      props.message = err.message
    }
    if (dev && logger) logger.info({ event, props }, 'Track Plausible event')
    if (plausible) plausible.trackEvent(event, { props })
  }

  async getDistinctId(email: string) {
    if (!email) return null

    const normalizedEmail = email.toLowerCase().trim()
    const encoder = new TextEncoder()
    const data = encoder.encode(normalizedEmail)
    const hashBuffer = await crypto.subtle.digest('SHA-256', data)
    const hashArray = Array.from(new Uint8Array(hashBuffer))
    const hashHex = hashArray
      .map(byte => byte.toString(16).padStart(2, '0'))
      .join('')
    const truncatedHash = hashHex.slice(0, 20)
    const firstTwoBytes = data.slice(0, 2)
    const hexPrefix = Array.from(firstTwoBytes)
      .map(byte => byte.toString(16).padStart(2, '0'))
      .join('')
      .padEnd(4, '0')

    return `di_v1_${hexPrefix}${truncatedHash}`
  }

  async getGAClientId() {
    const { gtag } = this

    if (gtag)
      return new Promise((resolve, reject) => {
        gtag('get', import.meta.env.VITE_GA_TRACKING_ID, 'client_id', resolve)
        setTimeout(() => reject(new Error('GA not responding')), 500)
      })
  }

  pageView({ canonicalPaths, headProps }: Vike.PageContext) {
    const { dev, gtag, logger, plausible } = this

    if (
      dev &&
      logger &&
      canonicalPaths &&
      canonicalPaths.relative &&
      headProps &&
      headProps.title
    )
      logger.info(
        { title: headProps.title, url: canonicalPaths.relative },
        'Track pagevew'
      )

    if (plausible && canonicalPaths && canonicalPaths.absolute)
      plausible.trackPageview({
        url: canonicalPaths.absolute
      })

    if (
      gtag &&
      canonicalPaths &&
      canonicalPaths.absolute &&
      headProps &&
      headProps.title
    )
      gtag('event', 'page_view', {
        page_location: canonicalPaths.absolute,
        page_title: headProps.title
      })
  }
}

// eslint-disable-next-line @typescript-eslint/no-unsafe-function-type
let fbq: Function | undefined
// eslint-disable-next-line @typescript-eslint/no-unsafe-function-type
let gtag: Function | undefined
// eslint-disable-next-line @typescript-eslint/no-unsafe-function-type
let lintrk: Function | undefined
let plausible: PlausibleReturn | undefined
let posthog: typeof PostHog | undefined

// Configure trackers
if (!import.meta.env.SSR) {
  if ('fbq' in window && typeof window.fbq === 'function') fbq = window.fbq
  if ('gtag' in window && typeof window.gtag === 'function') gtag = window.gtag
  if ('lintrk' in window && typeof window.lintrk === 'function')
    lintrk = window.lintrk

  const domain = import.meta.env.VITE_DOMAIN
  if (domain)
    plausible = Plausible({
      domain,
      trackLocalhost: false
    })

  const postHogToken = import.meta.env.VITE_POSTHOG_TOKEN
  if (postHogToken)
    posthog = PostHog.init(postHogToken, {
      api_host: 'https://us.i.posthog.com',
      person_profiles: 'identified_only' // or 'always' to create profiles for anonymous users as well
    })
}

export const tracker = !import.meta.env.SSR
  ? new Tracker({
      dev: import.meta.env.DEV,
      fbq,
      gtag,
      lintrk,
      logger,
      plausible,
      posthog
    })
  : new Tracker()
