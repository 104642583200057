<template>
  <v-app v-scroll="onScroll">
    <v-no-ssr>
      <CartDrawer v-model="cartDrawer" />
    </v-no-ssr>

    <v-overlay
      :model-value="overlay"
      class="align-center justify-center"
      persistent
    >
      <v-progress-circular
        :size="80"
        color="red-darken-2"
        indeterminate
      ></v-progress-circular>
    </v-overlay>

    <a href="/" style="position: fixed; z-index: 1005; left: 24px; top: 0px">
      <HyperdriveLogo collapse :elevate="top" />
    </a>

    <v-app-bar v-if="siteConfig" :height="APP_BAR_HEIGHT" :flat="top">
      <v-sheet class="d-flex flex-column" color="transparent" width="100%">
        <v-sheet
          :height="CTA_BAR_HEIGHT"
          class="d-flex align-center justify-end justify-sm-center px-4"
        >
          <span class="d-flex d-md-none" style="min-width: 137px"></span>

          <span
            v-if="siteConfig.header_cta && siteConfig.header_cta.title"
            class="d-none d-sm-flex text-subtitle-1 text-truncate mr-2"
          >
            <StyledText :value="siteConfig.header_cta.title" />
          </span>
          <v-btn
            v-if="
              siteConfig.header_cta &&
              siteConfig.header_cta.action &&
              siteConfig.header_cta.href
            "
            :href="siteConfig.header_cta.href"
            class="text-none text-decoration-underline font-weight-bold"
            color="red-darken-2"
            slim
            variant="text"
            >{{ siteConfig.header_cta.action }}</v-btn
          >
        </v-sheet>

        <v-divider />

        <v-sheet
          :height="MENU_BAR_HEIGHT"
          color="transparent"
          class="d-flex align-center px-4 ml-auto"
        >
          <v-lazy>
            <div v-if="isDev" class="bg-secondary pa-2 mr-1">
              {{ breakpointName }}
            </div>
          </v-lazy>

          <v-btn
            v-for="(item, index) in navItems"
            :key="item.href"
            :active="!item.menuGroups && item.href === canonicalPaths.relative"
            :append-icon="item.menuGroups ? mdiChevronDown : undefined"
            :class="`d-none d-${item.visible}-flex`"
            :href="item.menuGroups ? undefined : item.href"
            class="text-none"
            exact
            rounded="pill"
            size="large"
            @click="
              item.menuGroups
                ? openNavItem(index)
                : typeof item.click === 'function'
                  ? item.click()
                  : undefined
            "
            >{{ item.title }}</v-btn
          >

          <v-menu>
            <template #activator="{ props }">
              <v-btn
                v-bind="props"
                :icon="mdiMenu"
                aria-label="Open Menu"
                class="d-flex d-lg-none ml-2"
              />
            </template>

            <v-list>
              <v-list-item
                v-for="(item, index) in navItems"
                :key="item.href"
                :active="item.href === canonicalPaths.relative"
                :append-icon="item.menuGroups ? mdiChevronDown : undefined"
                :class="`d-flex-inline d-${item.visible}-none`"
                :href="item.menuGroups ? undefined : item.href"
                @click="
                  item.menuGroups
                    ? openNavItem(index)
                    : typeof item.click === 'function'
                      ? item.click()
                      : undefined
                "
              >
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>

          <v-lazy>
            <v-btn
              :icon="dark ? mdiWeatherNight : mdiWeatherSunny"
              aria-label="Toggle Dark"
              class="ml-2"
              @click="toggleDark()"
            />
          </v-lazy>

          <v-lazy>
            <v-btn
              v-if="hasCartSession"
              :icon="mdiCartArrowRight"
              aria-label="Open Cart"
              class="ml-2"
              color="light-blue-darken-2"
              variant="tonal"
              @click="openCart"
            />
          </v-lazy>

          <v-btn
            v-if="
              siteConfig.nav_cta &&
              siteConfig.nav_cta.action &&
              siteConfig.nav_cta.href
            "
            :append-icon="mdiArrowRight"
            :href="siteConfig.nav_cta.href"
            aria-label="Contact Us"
            class="text-none d-none d-sm-flex ml-2"
            exact
            rounded="pill"
            size="large"
            variant="outlined"
            >{{ siteConfig.nav_cta.action }}</v-btn
          >
          <v-btn
            v-if="siteConfig.nav_cta"
            :href="siteConfig.nav_cta.href"
            :icon="mdiEmailOutline"
            aria-label="Contact Us"
            class="d-flex d-sm-none ml-2"
            exact
            rounded="pill"
            variant="outlined"
          />
        </v-sheet>
      </v-sheet>
    </v-app-bar>

    <v-main :style="{ marginTop: `${-MENU_BAR_HEIGHT}px` }">
      <slot />

      <v-no-ssr>
        <v-snackbar
          v-model="cartReminderSnackbar"
          :timeout="-1"
          color="light-blue-darken-2"
          location="top right"
        >
          <v-icon :icon="mdiCartArrowRight" />

          <template #actions>
            <v-btn variant="text" @click="openCart">Continue Checkout</v-btn>
            <v-btn variant="text" @click="dismissCartReminder">Dismiss</v-btn>
          </template>
        </v-snackbar>

        <v-snackbar
          v-model="cookieManageSnackbar"
          :timeout="-1"
          multi-line
          vertical
        >
          <v-row dense>
            <v-col>
              <v-switch
                v-model="consentAdvertising"
                color="secondary"
                label="Advertising"
                hide-details
              ></v-switch>
              Advertising cookies are used to track visitors across websites to
              allow publishers to display relevant and engaging advertisements.
            </v-col>
          </v-row>

          <v-row dense>
            <v-col>
              <v-switch
                v-model="consentAnalytics"
                color="secondary"
                label="Analytics"
                hide-details
              ></v-switch>
              Analytics cookies help us understand how visitors engage with our
              website.
            </v-col>
          </v-row>

          <v-row dense>
            <v-col>
              <v-switch
                v-model="consentNecessary"
                color="secondary"
                disabled
                label="Necessary"
                hide-details
              ></v-switch>
              Necessary cookies are essential for the website to function and
              cannot be disabled. These are used for website preferences,
              logging in, or making purchases.
            </v-col>
          </v-row>

          <template #actions>
            <v-btn
              color="secondary"
              variant="flat"
              @click="applyCookieSettings"
            >
              Save Changes
            </v-btn>
          </template>
        </v-snackbar>

        <v-snackbar
          v-model="cookieNoticeSnackbar"
          :timeout="-1"
          multi-line
          vertical
        >
          <v-row dense>
            <v-col>
              We use cookies to ensure you get the best experience on our
              website. Some cookies are necessary for our site and services to
              function. Other cookies are optional, such as advertising and
              analytics. You can accept or reject all optional cookies. Without
              a selection, our default cookie settings will apply. You can
              change these settings at any time under Resources. See our
              <a class="text-white" href="/privacy-policy">Privacy Policy</a> to
              learn more.
            </v-col>
          </v-row>

          <template #actions>
            <v-btn
              class="mr-2"
              color="secondary"
              variant="flat"
              @click="applyCookieSettings(true)"
            >
              Accept All
            </v-btn>
            <v-btn
              class="mr-2"
              color="secondary"
              variant="flat"
              @click="applyCookieSettings(false)"
            >
              Reject All
            </v-btn>
            <v-btn color="secondary" variant="flat" @click="openCookieSettings">
              Manage
            </v-btn>
          </template>
        </v-snackbar>
      </v-no-ssr>

      <v-dialog
        v-model="navDialog"
        :scrim="true"
        :style="{ marginTop: `${APP_BAR_HEIGHT}px` }"
        class="align-start justify-lg-end"
        max-width="1000"
      >
        <v-card
          ref="navCard"
          :key="navItemIndex"
          v-resize="onResize"
          v-scroll.self="onScrollNavCard"
          class="nav-card"
        >
          <v-list>
            <v-list-subheader>{{ navItem.title }} menu</v-list-subheader>
          </v-list>

          <template
            v-for="(menuGroup, menuGroupIndex) in navItem.menuGroups"
            :key="`menuGroup-${menuGroupIndex}`"
          >
            <v-divider />

            <v-list>
              <v-list-subheader v-if="menuGroup.header">{{
                menuGroup.header
              }}</v-list-subheader>

              <v-list-item
                v-for="(listItem, listIndex) in menuGroup.listItems"
                :key="`menuGroup-${menuGroupIndex}-listIndex-${listIndex}`"
                :value="`menuGroup-${menuGroupIndex}-listIndex-${listIndex}`"
                :append-icon="
                  listItem.key ? mdiChevronDown : hrefIcon(listItem.href, false)
                "
                :class="
                  !listItem.parentKey ||
                  listItem.parentKey === navSelectedParentKey
                    ? ''
                    : 'd-none'
                "
                :href="listItem.href"
                :target="hrefTarget(listItem.href)"
                exact
                @click="
                  typeof listItem.click === 'function'
                    ? listItem.click(listItem)
                    : undefined
                "
                ><v-list-item-title
                  ><span
                    :class="
                      listItem.key ? '' : listItem.parentKey ? 'ml-6' : ''
                    "
                    >{{ listItem.title }}</span
                  ></v-list-item-title
                >
              </v-list-item>
            </v-list>
          </template>
        </v-card>

        <div
          class="d-flex justify-center"
          style="opacity: 0.5; position: absolute; bottom: 0; width: 100%"
        >
          <v-fade-transition>
            <v-icon v-show="!navBottom" color="white">{{
              mdiArrowDown
            }}</v-icon>
          </v-fade-transition>
        </div>
      </v-dialog>

      <v-dialog v-model="videoDialog" width="auto">
        <VideoPlayer v-if="videoDialog && videoObject" :value="videoObject" />
      </v-dialog>
    </v-main>

    <v-footer v-if="siteConfig" color="surface-light">
      <FooterSection />
    </v-footer>
  </v-app>
</template>

<script setup>
import { computed, onMounted, reactive, ref, watch } from 'vue'
import { useDisplay, useTheme } from 'vuetify'
import { usePageContext } from '#root/renderer/usePageContext'
import { useProvideCartStore } from '#root/lib/useCartStore'
import { useProvideOccasionsStore } from '#root/lib/useOccasionsStore'
import { useCart } from '#root/lib/useCart'
import { useCartSession } from '#root/lib/useCartSession'
import { useConsent } from '#root/lib/useConsent'
import { useEventBus, useStorage, useToggle } from '@vueuse/core'
import {
  APP_BAR_HEIGHT,
  CTA_BAR_HEIGHT,
  MENU_BAR_HEIGHT
} from '#root/lib/consts'
import { hrefIcon, hrefTarget } from '#root/lib/utils'
import {
  mdiArrowDown,
  mdiArrowRight,
  mdiCartArrowRight,
  mdiChevronDown,
  mdiEmailOutline,
  mdiMenu,
  mdiWeatherNight,
  mdiWeatherSunny
} from '@mdi/js'

const { name: breakpointName } = useDisplay()
const {
  businessMenuItems,
  canonicalPaths,
  data: pageData,
  individualMenuItems,
  siteConfig,
  whatMenuItems
} = usePageContext()
const { hasCartSession } = useCartSession()
const isDev = import.meta.env.DEV
const isReady = ref(false)
const theme = useTheme()
const dark = useStorage('dark', theme.global.current.value.dark)
const toggleDark = useToggle(dark)
const top = ref(true)

useProvideOccasionsStore()
useProvideCartStore()

const { cartDrawer, cartReminderSnackbar, dismissCartReminder, openCart } =
  useCart({
    autoAddItemFromHash:
      pageData && pageData.product && typeof pageData.product === 'object'
        ? false
        : true
  })

const {
  applyCookieSettings,
  checkForConsent,
  consentAdvertising,
  consentAnalytics,
  consentNecessary,
  cookieManageSnackbar,
  cookieNoticeSnackbar,
  openCookieSettings
} = useConsent({
  onOpen() {
    navDialog.value = false
  }
})

const navBottom = ref(false)
const navCard = ref(null)
const navDialog = ref(false)
const navGroups = reactive({
  default: [
    {
      menuGroups: [
        ...(businessMenuItems.length
          ? [
              {
                listItems: businessMenuItems.map(menuItem => ({
                  href: `/explore/${menuItem.item.static_page.page_slug}`,
                  title: menuItem.item.title
                }))
              }
            ]
          : []),
        {
          listItems: [
            {
              href: '/consulting',
              title: 'Consulting'
            },
            {
              href: '/staffing',
              title: 'Staffing'
            }
          ]
        },
        {
          listItems: [
            {
              click: onClickParentItem,
              key: 'okrs',
              title: 'OKRs: Goals & Metrics'
            },
            {
              href: '/okrs',
              parentKey: 'okrs',
              title: 'OKRs Overview'
            },
            {
              href: '/training/courses/okr',
              parentKey: 'okrs',
              title: 'OKR Certification Courses'
            },
            {
              href: '/assessments/do-i-need-okrs',
              parentKey: 'okrs',
              title: 'Assess Whether You Need OKRs'
            },
            {
              href: '/assessments/okr-health-check',
              parentKey: 'okrs',
              title: 'OKRs Health Check'
            },
            {
              href: 'https://www.scaledokrs.com/',
              parentKey: 'okrs',
              title: 'Scaled OKRs Site'
            }
          ]
        }
      ],
      title: 'Business',
      visible: 'md'
    },
    {
      href: '/training',
      menuGroups: [
        {
          listItems: [
            {
              href: '/training',
              title: 'Training Overview'
            },
            {
              href: '/training/schedule',
              title: 'Upcoming Training Schedule'
            },
            {
              href: '/training/agile-teams',
              title: 'Group & Private Training'
            }
          ]
        },
        {
          listItems: [
            {
              href: '/training/courses',
              title: 'Certified Courses Overview'
            },
            {
              href: '/training/workshops',
              title: 'Workshops Overview'
            },
            {
              href: '/trainers',
              title: 'Meet the Trainers'
            }
          ]
        },

        {
          listItems: whatMenuItems.reduce((items, menuItem) => {
            return [
              ...items,
              {
                click: onClickParentItem,
                key: menuItem.item.id,
                title: menuItem.item.title
              },
              ...menuItem.item.products.map(product => ({
                href:
                  product.products_id.occasion_type.code === 'C'
                    ? `/training/courses/${product.products_id.page_slug}`
                    : product.products_id.occasion_type.code === 'W'
                      ? `/training/workshops/${product.products_id.page_slug}`
                      : '',
                parentKey: menuItem.item.id,
                title: product.products_id.name
              }))
            ]
          }, [])
        }
      ],
      title: 'Training',
      visible: 'md'
    },
    ...(individualMenuItems.length
      ? [
          {
            menuGroups: [
              {
                listItems: individualMenuItems.map(menuItem => ({
                  href: `/explore/${menuItem.item.static_page.page_slug}`,
                  title: menuItem.item.title
                }))
              }
            ],
            title: 'Individuals',
            visible: 'md'
          }
        ]
      : []),
    {
      href: '/events',
      title: 'Events',
      visible: 'lg'
    },
    {
      href: '/resources',
      menuGroups: [
        {
          listItems: [
            {
              href: '/case-studies',
              title: 'Case Studies'
            },
            {
              href: 'https://explore.hyperdriveagile.com/goals-and-gamechangers',
              title: 'Podcast: Goals & Game Changers'
            },
            {
              href: '/faqs',
              title: 'FAQs'
            },
            {
              href: '/resources',
              title: 'All Resources (Articles, Downloads and Videos)'
            },
            {
              click: () => openCookieSettings(),
              title: 'Manage Cookie Settings'
            }
          ]
        }
      ],
      title: 'Resources',
      visible: 'lg'
    },
    {
      href: '/about',
      title: 'About',
      visible: 'lg'
    }
  ]
})
const navItem = computed(() => navItems.value[navItemIndex.value] || [])
const navItemIndex = ref(0)
const navItems = computed(() => navGroups.default)
const navSelectedParentKey = ref('')
const appBus = useEventBus('app')
appBus.on(data => {
  if (data === 'ready') {
    theme.global.name.value = dark.value ? 'dark' : 'light'
    isReady.value = true
  }
})

const overlay = ref(false)
const overlayBus = useEventBus('overlay')
overlayBus.on(show => {
  overlay.value = show
})

const videoBus = useEventBus('video')
videoBus.on(video => {
  videoObject.value = video
  videoDialog.value = true
})
const videoDialog = ref(false)
const videoObject = ref(null)

function onResize() {
  updateNavBottom()
}
function onScroll(e) {
  if (e.target.documentElement.scrollTop < CTA_BAR_HEIGHT) {
    top.value = true
  } else {
    top.value = false
    checkForConsent()
  }
}

function onClickParentItem(listItem) {
  navSelectedParentKey.value =
    navSelectedParentKey.value === listItem.key ? '' : listItem.key
}
function onScrollNavCard() {
  updateNavBottom()
}
function openNavItem(index) {
  navSelectedParentKey.value = ''
  navItemIndex.value = index
  navDialog.value = true
}
function updateNavBottom() {
  const el = navCard.value && navCard.value.$el
  navBottom.value =
    el && el.scrollHeight - Math.round(el.scrollTop) === el.clientHeight
}

onMounted(async () => {
  watch(
    dark,
    value => {
      theme.global.name.value = value ? 'dark' : 'light'
    },
    { immediate: false }
  )
})
</script>

<style lang="scss">
@use '../settings';
@use 'sass:map';

.blockquote p:before {
  content: '“';
}
.blockquote p:after {
  content: '”';
}

.img-responsive {
  max-width: 100%;
  height: auto;
}

.nav-card {
  max-height: calc(100vh - 180px) !important;
  overflow-y: scroll !important;
}
.nav-card .v-list {
  overflow: unset !important;
}

@mixin sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}
.sticky {
  @include sticky;
}
.sticky-sm {
  @media #{map.get(settings.$display-breakpoints, 'sm-and-up')} {
    @include sticky;
  }
}
.sticky-md {
  @media #{map.get(settings.$display-breakpoints, 'md-and-up')} {
    @include sticky;
  }
}
.sticky-lg {
  @media #{map.get(settings.$display-breakpoints, 'lg-and-up')} {
    @include sticky;
  }
}
.sticky-xl {
  @media #{map.get(settings.$display-breakpoints, 'xl-and-up')} {
    @include sticky;
  }
}
.sticky-xxl {
  @media #{map.get(settings.$display-breakpoints, 'xxl')} {
    @include sticky;
  }
}

.text-truncate-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.text-truncate-3 {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.text-truncate-4 {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.text-truncate-5 {
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.text-truncate-6 {
  display: -webkit-box;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.text-truncate-7 {
  display: -webkit-box;
  -webkit-line-clamp: 7;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.text-truncate-8 {
  display: -webkit-box;
  -webkit-line-clamp: 8;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.text-truncate-9 {
  display: -webkit-box;
  -webkit-line-clamp: 9;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.text-truncate-10 {
  display: -webkit-box;
  -webkit-line-clamp: 10;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.text-truncate-11 {
  display: -webkit-box;
  -webkit-line-clamp: 11;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.text-truncate-12 {
  display: -webkit-box;
  -webkit-line-clamp: 12;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
</style>
