<template>
  <v-row v-if="siteConfig.newsletter_form">
    <v-col>
      <v-row v-if="isSubmitted">
        <v-col>
          <v-alert
            :color="isSubmitError ? 'error' : 'success'"
            :type="isSubmitError ? 'error' : 'success'"
          >
            <span v-if="isSubmitError">
              Sorry! Our newsletter form may be having a bad day. Please
              <a
                :href="
                  mailToHref({
                    email: siteConfig.info_email,
                    subject: 'Newsletter'
                  })
                "
                class="text-white"
                target="_blank"
                >contact us via email</a
              >, or perhaps try a bit later.</span
            ><span v-else-if="siteConfig.newsletter_form.success_text">
              {{ siteConfig.newsletter_form.success_text }}
            </span>
          </v-alert>
        </v-col>
      </v-row>

      <v-form v-else ref="form" v-model="isValid" lazy-validation>
        <v-row>
          <v-col v-if="siteConfig.newsletter_form.agree_label" cols="12">
            <v-checkbox
              id="newsletter_form_agree"
              v-model="formData.agree"
              :label="siteConfig.newsletter_form.agree_label"
              hide-details
            ></v-checkbox
          ></v-col>

          <v-col v-if="shownFields.email" cols="12">
            <v-text-field
              id="newsletter_form_email"
              ref="email"
              v-model.trim="formData.email"
              :rules="[requiredRule, emailRule]"
              density="compact"
              flat
              hide-details="auto"
              label="Your Email *"
              theme="light"
              type="email"
              variant="solo"
            ></v-text-field>
          </v-col>

          <v-col v-if="shownFields.name" cols="12">
            <v-text-field
              id="newsletter_form_name"
              ref="name"
              v-model.trim="formData.name"
              density="compact"
              flat
              hide-details="auto"
              label="Your Name"
              theme="light"
              variant="solo"
            ></v-text-field>
          </v-col>

          <v-col cols="12">
            <v-btn
              v-if="formUrl"
              :disabled="!formData.agree || !isValid"
              class="text-capitalize"
              color="secondary"
              rounded="pill"
              size="large"
              theme="light"
              @click="submit"
              >{{ submitText }}</v-btn
            >
            <v-btn
              v-else
              :disabled="!formData.agree || !isValid"
              :href="formMailToHref"
              class="text-capitalize"
              color="secondary"
              rounded="pill"
              size="large"
              theme="light"
              >{{ submitText }}</v-btn
            >
          </v-col>
        </v-row>
      </v-form>
    </v-col>
  </v-row>
</template>

<script setup lang="ts">
import { computed, reactive, ref } from 'vue'
import { tracker } from '#root/lib/tracker'
import { usePageContext } from '#root/renderer/usePageContext'
import { emailRule, requiredRule } from '#root/lib/form-rules'
import { mailToHref } from '#root/lib/utils'

const FIELDS_DEFAULT = {
  email: true,
  name: true
}
const EMAIL_FIELDS = [
  {
    key: 'name',
    name: 'Name'
  },
  {
    key: 'email',
    name: 'Email'
  }
]
const HS_FIELDS = [
  {
    key: 'name',
    name: 'firstname',
    objectTypeId: '0-1'
  },
  {
    key: 'email',
    name: 'email',
    objectTypeId: '0-1'
  }
]

const props = defineProps({
  fields: {
    default: () => {},
    type: Object
  },
  submitText: {
    default: 'Subscribe',
    type: String
  }
})

const { canonicalPaths, siteConfig } = usePageContext()
const isSubmitted = ref(false)
const isSubmitError = ref(false)
const isValid = ref(true)
const form = ref(null)
const formData = reactive({
  agree: false,
  email: null,
  name: null
})
const formUrl = ref(import.meta.env.VITE_HS_NEWSLETTER_FORM_URL)
const shownFields = computed(() => {
  return Object.assign({}, FIELDS_DEFAULT, props.fields)
})
const formMailToHref = computed(() => {
  const body = EMAIL_FIELDS.filter(item => shownFields.value[item.key])
    .map(({ key, ...spec }) => `${spec.name}:\n${formData[key]}`)
    .join('\n\n')
  return mailToHref({
    body,
    email: siteConfig.info_email,
    subject: 'Newsletter'
  })
})

async function submit() {
  if (!form.value) return
  const result = await form.value.validate()
  if (!result.valid) return

  try {
    const data = {
      fields: HS_FIELDS.filter(item => shownFields.value[item.key]).map(
        ({ key, ...spec }) => ({
          value: formData[key],
          ...spec
        })
      ),
      legalConsentOptions: {
        consent: {
          // Include this object when GDPR options are enabled
          consentToProcess: true,
          text: siteConfig.newsletter_form.consent_text,
          communications: [
            {
              value: true,
              subscriptionTypeId:
                siteConfig.newsletter_form.subscription_type_id,
              text: siteConfig.newsletter_form.agree_label
            }
          ]
        }
      },
      context: {
        pageUri: canonicalPaths.absolute,
        pageName: canonicalPaths.relative
      }
    }
    const resp = await fetch(formUrl.value, {
      method: 'POST',
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    })
    if (resp.ok) {
      if (formData.email && formData.name) {
        let distinctId
        try {
          distinctId = await tracker.getDistinctId(formData.email)
        } catch (err) {
          console.error({ err }, 'getDistinctId error')
        }
        if (distinctId && tracker.posthog) {
          tracker.posthog.identify(distinctId, {
            email: formData.email,
            name: formData.name
          })
        }
        if (tracker.posthog) {
          tracker.posthog.capture('lead:newsletter_form_submit', {})
        }
      }
      tracker.googleEvent('signup', {})
      tracker.plausibleEvent('newsletterSend', {})
    } else {
      isSubmitError.value = true
      tracker.plausibleEvent('newsletterError', {
        message: 'Success response not returned.'
      })
    }
  } catch (err) {
    isSubmitError.value = true
    tracker.plausibleEvent('newsletterError', {}, err)
  } finally {
    isSubmitted.value = true
  }
}
</script>
