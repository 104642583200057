import { onMounted, ref } from 'vue'
import { useStorage } from '@vueuse/core'

export function useConsent(options) {
  // Selections
  const consent = useStorage('consent-selections', null)
  const consentAdvertising = ref(false)
  const consentAnalytics = ref(false)
  const consentNecessary = ref(true)

  // UI state
  const cookieManageSnackbar = ref(false)
  const cookieNoticeSnackbar = ref(false)

  function applyCookieSettings(accept) {
    if (typeof accept === 'boolean') {
      consentAdvertising.value = accept
      consentAnalytics.value = accept
    }
    cookieManageSnackbar.value = false
    cookieNoticeSnackbar.value = false

    updateConsent()
  }

  function checkForConsent() {
    if (consent.value === null && !cookieNoticeSnackbar.value) {
      consentAdvertising.value = true
      consentAnalytics.value = true
      cookieNoticeSnackbar.value = true
      cookieManageSnackbar.value = false
    }
  }

  function openCookieSettings() {
    cookieManageSnackbar.value = true
    cookieNoticeSnackbar.value = false

    if (
      options &&
      typeof options === 'object' &&
      typeof options.onOpen === 'function'
    )
      options.onOpen()
  }

  function updateConsent() {
    const advertising = consentAdvertising.value
    const analytics = consentAnalytics.value
    const selections = []

    if (advertising) selections.push('advertising')
    if (analytics) selections.push('analytics')

    consent.value = selections.length ? selections.join(',') : 'reject'

    if ('gtag' in window && typeof window.gtag === 'function') {
      window.gtag('consent', 'update', {
        ad_storage: advertising ? 'granted' : 'denied',
        ad_user_data: advertising ? 'granted' : 'denied',
        ad_personalization: advertising ? 'granted' : 'denied',
        analytics_storage: analytics ? 'granted' : 'denied',
        functionality_storage: 'granted',
        personalization_storage: 'granted',
        security_storage: 'granted'
      })
      window.gtag('set', 'ads_data_redaction', !advertising)
    }
  }

  onMounted(async () => {
    if (consent.value !== null) {
      const selections = consent.value.split(',')
      consentAdvertising.value = selections.includes('advertising')
      consentAnalytics.value = selections.includes('analytics')
    }
  })

  return {
    applyCookieSettings,
    checkForConsent,
    consent,
    consentAdvertising,
    consentAnalytics,
    consentNecessary,
    cookieManageSnackbar,
    cookieNoticeSnackbar,
    openCookieSettings,
    updateConsent
  }
}
